<template>
    <div class="body-right-box" :class="{'close-left-box':isCloseLeftBox}">
        <div class="tag-list">
            <div class="tag-scroll" ref="tagS">
                <ul class="clearfix" ref="tagW" :style="{ width: tagWidth }">
                    <li v-for="(item,index) in tagList" :class="{'tagcur':item.isShow}"   @click="changePage(item)" :key="index">
                        <span class="tag-text" v-text="item.name"></span>
                       <!-- <router-link :to="item.path" class="tag-text">{{item.name}}</router-link>-->
                        <a href="javascript:;" @click.stop="closePage(index)" v-if="index>0" class="tag-close"></a>
                    </li>
                </ul>
                <div class="tag-left-right" v-show="tagBtnShow">
                    <a href="javascript:void(0)" class="tag-left"  @click="leftScroll">&lt;</a>
                    <a href="javascript:void(0)" class="tag-right"  @click="rightScroll">&gt;</a>
                </div>
            </div>
        </div>
        <div class="right-content"  :style="s">
                <transition-group name="el-zoom-in-bottom">
                    <component :is="citem.component"  v-for="citem in tagList" :key="citem.name"  v-show="citem.isShow"></component>
                </transition-group>
         </div>
</div>
</template>

<script>
    import Event from '@/scripts/event.js';
    import RouteManage from '@/scripts/routemanage';
    import Cookie from '@/scripts/cookie.js';
    import config from '@/scripts/config.js';

    export default {
        data: function () {

            var vPath = `/${config.subdomain}`;

            return {
                s: {
                    height: 0
                },
                tagWidth:'auto',
                tagBtnShow:false,
                tagList: [{
                    name: "工作台",
                    path: vPath+"/home",
                    isShow: true,
                    zIndex: 999,
                    component: resolve => require(['@/components/business/workbench/workbench.vue'], resolve)
                }
                ],
                isCloseLeftBox: false
            }
        },
        beforeCreate: function () {
            var _this = this;
            Event.$on("changeContentHeight", function (msg) {
                _this.beforeCreateData = window.innerHeight - parseInt(msg, 10) - 38;
                _this.changeBodyH();
            })
        },
        watch: {//深度 watcher
                tagList: {
                    handler: function (val) {
                        var tw = 111;
                        for (var i = 0; i < val.length; i++){
                            if (i > 0) {
                                tw = tw + parseInt(val[i].name.length * 13 + 61);
                            }
                        }
                        this.tagWidth = tw + 'px';
                        if (parseInt(this.tagWidth) > this.$refs.tagS.clientWidth){
                            this.tagBtnShow=true;
                           // this.$refs.tagS.scrollLeft = parseInt(this.tagWidth) - this.$refs.tagS.clientWidth;
                            var self = this;
                            setTimeout(function () {
                                self.leftScroll(parseInt(self.tagWidth) - self.$refs.tagS.clientWidth)
                            }, 10);
                           
                        }else{
                            this.tagBtnShow=false;
                        }

                    }
                }
        },

        methods: {
            changeBodyH: function () {
                this.s.height = this.beforeCreateData + "px";
            },
            changePage: function (obj) {
                for (var i = 0; i < this.tagList.length; i++) {
                    this.tagList[i].isShow = false;
                    this.tagList[i].zIndex = 1;
                }
                obj.isShow = true;
                obj.zIndex = 999;
            },
            closeAllTag: function () {
                this.tagList[0].isShow = true;
                this.tagList[0].zIndex = 999;
                this.$router.push({ path: this.tagList[0].path });
                this.tagList.splice(1, this.tagList.length-1);

            },
            reloadAllTag: function () {
                var child = this.$children[0];
                if (child && child.$children && child.$children.length > 1) {
                    for (var i = 1; i < child.$children.length; i++) {
                        var component = child.$children[i];
                        if (component != null && component.init != undefined) {
                            component.init();
                            component.$refs["control"].initialize();
                        }
                    }
                }
            },
            closePage: function (index) {
                //console.log(this.tagList);
                if (this.tagList[index].isShow && index > 0) {
                    this.tagList[index - 1].isShow = true;
                    this.tagList[index - 1].zIndex = 999;

                    this.$router.push({path: this.tagList[index - 1].path });
                }
                this.tagWidth = parseInt(this.$refs.tagW.style.width) - $(this.$refs.tagW.children[index]).width() + 'px';

                this.tagList.splice(index, 1);

              },
            leftScroll: function (len) {
                if (isNaN(len)) {
                    this.$refs.tagS.scrollLeft = this.$refs.tagS.scrollLeft - 50;
                } else {

                    this.$refs.tagS.scrollLeft = len;
                }
            },
            rightScroll:function(){
                this.$refs.tagS.scrollLeft=this.$refs.tagS.scrollLeft+50;
            }
        },
        mounted: function () {
            var _this = this;
            Event.$on("closeAllTag", function () {
                _this.closeAllTag();
            });
            Event.$on("reloadAllTag", function () {
                _this.reloadAllTag();
            });
            Event.$on("addTag", function (obj) {
                var isSame = null;
                for (var k = 0; k < _this.tagList.length; k++) {
                    var item = _this.tagList[k];
                    if (`/${ config.subdomain }${obj.id}` === item.path) {
                        isSame = item;
                    } else {
                        _this.tagList[k].isShow = false;
                        _this.tagList[k].zIndex = 1;
                    }
                }
                //console.log(123456);
                if (isSame == null) {
                    var objPath = `/${ config.subdomain }${obj.path}`;
                    const name = obj.name;
                    var o = {};
                    o.name = obj.name;
                    o.path = objPath;
                    o.isShow = true;
                    o.id = obj.id;
                    o.zIndex = 999;
                    var vPath = `/${ config.subdomain }/operations`;
				
                    o.component = RouteManage.routes.find(n => n.path == vPath + "/").children.find(n => n.path == objPath).component;
                    _this.tagList.push(o);
                    _this.$router.push(o.path);

                } else {
                    isSame.isShow = true;
                    isSame.zIndex = 999;
                    _this.$router.push(`/${ config.subdomain }${obj.path.toLowerCase()}`);
                }
            });
            Event.$on("isCloseLeftBox", function () {
                _this.isCloseLeftBox = !_this.isCloseLeftBox;
            });
        }
    }
</script>

